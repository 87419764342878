import { useState, useEffect, Fragment } from "react";
import {
  Paper,
  Title,
  Container,
  Group,
  Button,
  Box,
  Divider,
  Stack,
  Text,
  NumberInput,
  Stepper,
  Flex,
  Image,
} from "@mantine/core";
import { TextInput, Switch, Select } from "react-hook-form-mantine";
import ACILogo from "../assets/ACI_NA_ID_Serbia.png";
import { useFieldArray, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import {
  IconCalendar,
  IconCircleCheckFilled,
  IconMapPin,
} from "@tabler/icons-react";
import {
  useCreateRegistration,
  useGetRegistrationEvent,
} from "../api/registrations";
import CountrySelect from "../components/CountrySelect";
import { countries } from "countries-list";

const FORM_STORAGE_KEY = "aci_registration_form";
const STEP_STORAGE_KEY = "aci_registration_step";

function SignUpACI() {
  const persistedForm = sessionStorage.getItem(FORM_STORAGE_KEY);
  const persistedStep = sessionStorage.getItem(STEP_STORAGE_KEY);
  const persistShowForm = persistedStep ? true : false;

  const defaultFormValues = persistedForm
    ? JSON.parse(persistedForm)
    : {
        companyName: "",
        email: "",
        vatNumber: "",
        additionalFields: {
          "Phone Number": "",
          "Registration Type": "",
          Country: "",
        },
        tickets: [
          {
            firstName: "",
            lastName: "",
            email: "",
            additionalFields: {
              "Institution Name": "",
              "Attend Networking Event": false,
              "Attend Lunch": false,
              "Attend Gala Dinner": false,
            },
          },
        ],
      };

  const [activeStep, setActiveStep] = useState(
    persistedStep ? Number(persistedStep) : 0
  );
  const [ticketCount, setTicketCount] = useState(1);
  const [showForm, setShowForm] = useState(persistShowForm);

  const {
    mutate,
    isPending: mutatePending,
    isSuccess,
  } = useCreateRegistration();

  const { data: event } = useGetRegistrationEvent({
    name: "ACI Serbia 50th Anniversary",
  });

  const { control, handleSubmit, watch, trigger } = useForm({
    defaultValues: defaultFormValues,
    mode: "onTouched",
    resolver: zodResolver(
      z.object({
        companyName: z.string().min(1, { message: "Company name is required" }),
        email: z.string().email({ message: "Invalid email address" }),
        vatNumber: z.string().min(1, { message: "VAT Number is required" }),
        additionalFields: z.object({
          "Phone Number": z.string(),
          "Registration Type": z.enum([
            "ACI Serbia member or guest",
            "Vendor",
            "Speaker",
            "Sponsor",
          ]),
          Country: z.string().min(1, { message: "Country is required" }),
        }),
        tickets: z.array(
          z.object({
            firstName: z.string().min(1, { message: "First name is required" }),
            lastName: z.string().min(1, { message: "Last name is required" }),
            email: z.string().email({ message: "Invalid email address" }),
            additionalFields: z.object({
              "Institution Name": z.string().min(1, {
                message: "Institution name is required",
              }),
              "Attend Networking Event": z.boolean(),
              "Attend Lunch": z.boolean(),
              "Attend Gala Dinner": z.boolean(),
            }),
          })
        ),
      })
    ),
  });

  const formValues = watch();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "tickets",
  });

  // Subscribe to form changes and persist them
  useEffect(() => {
    const subscription = watch((data) => {
      sessionStorage.setItem(FORM_STORAGE_KEY, JSON.stringify(data));
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  // Persist active step on change
  useEffect(() => {
    sessionStorage.setItem(STEP_STORAGE_KEY, activeStep.toString());
  }, [activeStep]);

  const nextStep = async (e) => {
    e && e.preventDefault();

    let isValid = false;

    if (activeStep === 0) {
      // Validate step 1: Invoice Details & Number of Tickets
      isValid = await trigger([
        "companyName",
        "vatNumber",
        "email",
        "additionalFields.Phone Number",
      ]);
    } else if (activeStep === 1) {
      // Validate step 2: Ticket Information for each ticket.
      // Build validation keys for each ticket's required fields.
      const ticketFields = fields.reduce((acc, _field, index) => {
        return acc.concat([
          `tickets[${index}].firstName`,
          `tickets[${index}].lastName`,
          `tickets[${index}].email`,
          `tickets[${index}].additionalFields.Institution Name`,
        ]);
      }, []);
      isValid = await trigger(ticketFields);
    }

    // Only proceed if the current step is valid.
    if (isValid) {
      setActiveStep((current) => (current < 2 ? current + 1 : current));
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  const prevStep = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setActiveStep((current) => (current > 0 ? current - 1 : current));
  };

  const handleTicketCountChange = (value) => {
    setTicketCount(value);
    if (value > fields.length) {
      for (let i = fields.length; i < value; i++) {
        append({
          firstName: "",
          lastName: "",
          email: "",
          additionalFields: {
            "Institution Name": "",
            "Attend Networking Event": false,
            "Attend Lunch": false,
            "Attend Gala Dinner": false,
          },
        });
      }
    } else {
      for (let i = fields.length; i > value; i--) {
        remove(i - 1);
      }
    }
  };

  const onSubmit = (data) => {
    mutate(
      { ...data, eventId: event?.id },
      {
        onSuccess: () => {
          sessionStorage.removeItem(FORM_STORAGE_KEY);
          sessionStorage.removeItem(STEP_STORAGE_KEY);
        },
      }
    );
  };

  const renderIntroduction = () => (
    <Stack spacing="xl" align="center" py={40}>
      <Title order={1} ta="center" mt="md">
        ACI Serbia 50th Anniversary
      </Title>

      <Text size="lg" ta="center" maw={600} mx="auto" my={20}>
        Join us for the banking industry event of the year in Aranđelovac,
        Serbia. Network with industry leaders, attend insightful sessions, and
        experience the best of Serbian hospitality from September 26-28, 2025.
      </Text>

      <Box my={20}>
        <Text fw={600} size="lg" ta="center" mb="sm">
          Event Details:
        </Text>
        <Flex justify="center" align="center">
          <IconCalendar size={24} />
          <Text ml="sm">September 26-28, 2025</Text>
        </Flex>
        <Flex justify="center" align="center">
          <IconMapPin size={24} />
          <Text ml="sm">Hotel Izvor - Aranđelovac, Serbia</Text>
        </Flex>
      </Box>

      <Button
        size="lg"
        color="#FDC300"
        onClick={() => setShowForm(true)}
        mt={20}
        radius="md"
      >
        Register Now
      </Button>
    </Stack>
  );

  // Step 1: Invoice Details and Number of Tickets
  const renderStep1 = () => {
    return (
      <>
        <Box>
          <Text fw={700} size="lg" mb="xs">
            Invoice Details
          </Text>
          <TextInput
            label="Company Name"
            placeholder="Your Company Name"
            control={control}
            name="companyName"
            size="md"
            required
          />
          <TextInput
            label="VAT Number"
            placeholder="123456789"
            mt="md"
            control={control}
            name="vatNumber"
            size="md"
            required
          />
          <TextInput
            label="Contact Email for Invoicing"
            placeholder="invoicing@company.com"
            mt="md"
            control={control}
            name="email"
            size="md"
            required
          />
          <CountrySelect
            label="Country"
            placeholder="Select your country"
            size="md"
            mt="md"
            control={control}
            name="additionalFields.Country"
            required
          />

          <TextInput
            label="Contact Phone (Optional)"
            placeholder="+381 64 567 8900"
            mt="md"
            control={control}
            name="additionalFields.Phone Number"
            size="md"
          />
        </Box>

        <Divider my="sm" />

        <Box>
          <Text fw={700} size="lg" mb="xs">
            Tickets
          </Text>
          <Select
            label="Registration Type"
            placeholder="Select registration type"
            description="The organizer will verify that the selected registration fee corresponds to the participant's status."
            data={[
              {
                label:
                  "ACI Serbia member or guest (23.000 RSD + VAT per ticket)",
                value: "ACI Serbia member or guest",
              },
              {
                label: "Vendor (75.000 RSD + VAT per ticket)",
                value: "Vendor",
              },
              { label: "Speaker", value: "Speaker" },
              { label: "Sponsor", value: "Sponsor" },
            ]}
            control={control}
            name="additionalFields.Registration Type"
            size="md"
            required
          />
          <NumberInput
            label="How many tickets would you like to register?"
            min={1}
            max={10}
            value={ticketCount}
            onChange={handleTicketCountChange}
            required
            size="md"
            mt="md"
          />
        </Box>
      </>
    );
  };

  // Step 2: Personal Information for Each Ticket
  const renderStep2 = () => (
    <>
      {fields.map((ticket, index) => (
        <Fragment key={ticket.id}>
          <Box>
            <Text fw={700} size="lg" mb="xs">
              Ticket #{index + 1} - Personal Information
            </Text>
            <TextInput
              label="First Name"
              placeholder="Petar"
              name={`tickets[${index}].firstName`}
              control={control}
              size="md"
              required
            />
            <TextInput
              label="Last Name"
              placeholder="Petrovic"
              mt="md"
              name={`tickets[${index}].lastName`}
              control={control}
              size="md"
              required
            />
            <TextInput
              label="Email Address"
              placeholder="your@email.com"
              mt="md"
              name={`tickets[${index}].email`}
              control={control}
              size="md"
              required
            />
            <TextInput
              label="Institution Name"
              placeholder="Your Institution"
              mt="md"
              name={`tickets[${index}].additionalFields.Institution Name`}
              control={control}
              size="md"
              required
            />

            <Text fw={700} size="lg" mb="xs" mt="md">
              Attendance Confirmation
            </Text>
            <Switch
              label="I will attend the Networking Event on Friday, September 26, 2025"
              size="md"
              control={control}
              name={`tickets[${index}].additionalFields.Attend Networking Event`}
              color="#FDC300"
            />
            <Switch
              label="I will attend Lunch on Saturday, September 27, 2025"
              size="md"
              mt="md"
              control={control}
              name={`tickets[${index}].additionalFields.Attend Lunch`}
              color="#FDC300"
            />
            <Switch
              label="I will attend the Gala Dinner on Saturday, September 27, 2025"
              size="md"
              mt="md"
              control={control}
              name={`tickets[${index}].additionalFields.Attend Gala Dinner`}
              color="#FDC300"
            />
          </Box>
          {index < fields.length - 1 && <Divider my="md" />}
        </Fragment>
      ))}
    </>
  );

  // Step 3: Final Review and Submit
  const renderStep3 = () => (
    <Box>
      <Text fw={700} size="xl" mb="lg" ta="center">
        Registration Summary
      </Text>

      <Text fw={600} size="lg" mb="xs">
        Invoice Details:
      </Text>
      <Text>Company Name: {formValues.companyName}</Text>
      <Text>VAT Number: {formValues.vatNumber}</Text>
      <Text>Contact Email: {formValues.email}</Text>
      <Text>
        Country: {countries[formValues.additionalFields.Country].name || "N/A"}
      </Text>
      <Text>
        Contact Phone:{" "}
        {formValues.additionalFields["Phone Number"] || "Not provided"}
      </Text>

      <Divider my="md" />

      <Text fw={600} size="lg">
        Ticket Information:
      </Text>
      <Text mb="xs">
        Registration Type: {formValues.additionalFields["Registration Type"]}
      </Text>

      {formValues.tickets.map((ticket, index) => (
        <Box key={index} mb="md">
          <Text fw={500}>Ticket #{index + 1}:</Text>
          <Text>Name: {ticket.firstName + " " + ticket.lastName}</Text>
          <Text>Email: {ticket.email}</Text>
          <Text>
            Institution: {ticket.additionalFields["Institution Name"]}
          </Text>
          <Text>
            Attending Networking Event:{" "}
            {ticket.additionalFields["Attend Networking Event"] ? "Yes" : "No"}
          </Text>
          <Text>
            Attending Lunch:{" "}
            {ticket.additionalFields["Attend Lunch"] ? "Yes" : "No"}
          </Text>
          <Text>
            Attending Gala Dinner:{" "}
            {ticket.additionalFields["Attend Gala Dinner"] ? "Yes" : "No"}
          </Text>
          {index < formValues.tickets.length - 1 && <Divider my="sm" />}
        </Box>
      ))}

      <Text fw={500} mt="lg" ta="center" color="dimmed">
        Please review your information above before submitting.
      </Text>
    </Box>
  );

  const renderStepContent = () => {
    switch (activeStep) {
      case 0:
        return renderStep1();
      case 1:
        return renderStep2();
      case 2:
        return renderStep3();
      default:
        return null;
    }
  };

  const renderSuccessMessage = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });

    return (
      <Stack spacing="xl" align="center" py={40}>
        <Title order={1} ta="center" mt="md">
          Thank you for registering for the ACI Serbia 50th Anniversary Annual
          Assembly!
        </Title>

        <IconCircleCheckFilled
          size={100}
          color="#FDC300"
          style={{ marginTop: 20 }}
        />
        <Text size="lg" ta="center" maw={600} mx="auto">
          Your registration has been successfully received, and your invoice
          will be sent to the provided contact email shortly. We look forward to
          celebrating this milestone with you! For any inquiries, feel free to
          contact us.
        </Text>
        <Text size="md" ta="center" maw={600} mx="auto" my={20} c="red">
          *Please note that this registration covers event participation only.
          Accommodation should be arranged separately with Hotel Izvor,
          Aranđelovac.
        </Text>
      </Stack>
    );
  };

  return (
    <Flex style={{ backgroundColor: "#2C2B57" }} mih={"100vh"}>
      <Container size="md" py="xl">
        <Paper radius="md" p="xl" withBorder>
          <Image src={ACILogo} alt="ACI-NA ID Serbia" h={100} fit="contain" />
          {isSuccess ? (
            renderSuccessMessage()
          ) : showForm ? (
            <>
              <Title order={2} ta="center" mt="md" mb={30}>
                Conference Registration
              </Title>

              <Stepper
                active={activeStep}
                breakpoint="sm"
                mb="xl"
                color="#FDC300"
              >
                <Stepper.Step label="Step 1" description="Invoice & Tickets" />
                <Stepper.Step
                  label="Step 2"
                  description="Attendee Information"
                />
                <Stepper.Step label="Step 3" description="Review & Submit" />
              </Stepper>

              <form onSubmit={handleSubmit(onSubmit)}>
                <Stack spacing="md">
                  {renderStepContent()}

                  <Group position="apart" mt="xl">
                    {activeStep > 0 && (
                      <Button
                        variant="default"
                        onClick={prevStep}
                        type="button"
                      >
                        Back
                      </Button>
                    )}

                    {activeStep < 2 ? (
                      <Button onClick={nextStep} color="#FDC300" type="button">
                        Next step
                      </Button>
                    ) : (
                      <Button
                        type="submit"
                        color="#FDC300"
                        loading={mutatePending}
                      >
                        Register {ticketCount}{" "}
                        {ticketCount === 1 ? "Ticket" : "Tickets"}
                      </Button>
                    )}
                  </Group>
                </Stack>
              </form>
            </>
          ) : (
            renderIntroduction()
          )}
        </Paper>
      </Container>
    </Flex>
  );
}

export default SignUpACI;
